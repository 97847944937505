import * as Data from '../../data'

import {Iframe as Component} from './iframe'

function mapState(state) {
  return {
    launchUrl: Data.getLaunchUrl(state),
  }
}

const connector = Data.connect(mapState)

export const Iframe = connector(Component)
