import * as React from 'react'
import * as Data from '../data'
import loadable from '@loadable/component'
import { Iframe } from './iframe'

const SolidPlayngoLauncher = loadable(() => import('./solid-playngo-launcher'))
const PlayNGoLauncher = loadable(() => import('./play-n-go-launcher'))

export function IframeLauncher() {
  const game = Data.useGame()

  if (game.provider === 'solidgaming' && game.displayProvider === 'playngo') {
    return <SolidPlayngoLauncher />
  }

  if (game.provider === 'playngo') {
    return <PlayNGoLauncher />
  }

  return <Iframe />
}

// for @loadable/components
export default IframeLauncher
