import * as React from 'react'
import PropTypes from 'prop-types'


export function Iframe(props) {
  return (
    <object
      style={{height: '100%', width: '100%'}}
      data={props.launchUrl}
      type="text/html"
      id="game"
    />
  )
  
}

Iframe.propTypes = {
  launchUrl: PropTypes.string.isRequired,
}
